import { functional } from '@think-internet/zeus-frontend-package'
import { useParams } from 'react-router-dom'
import Routes from '../../../../redux/routes'
import { createRef, useEffect, useState } from 'react'
import Loading from '../../../UI/Loading/Loading'
import { useSelector } from 'react-redux'
import props from '../../../../redux/props'
import SignatureCanvas from 'react-signature-canvas'
import ReactSignatureCanvas from 'react-signature-canvas'
import Button from '../../../UI/Button/Button'
import { FreigabeState } from '../../../../types/Versammlung/Versammlung'
import Container from '../../../UI/Container/Container'
import Textarea from '../../../UI/Textarea/Textarea'
import useGetVersammlung from '../../../hooks/useGetVersammlung'
import Accordion from '../../../UI/Accordion/Accordion'

enum Valid {
  PENDING = 'PENDING',
  VALID = 'VALID',
  INVALID = 'INVALID',
}

enum PageStatus {
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
}

const Signature = () => {
  const { versammlungUUID, mail } = useParams()
  const validateSignature = functional.use(Routes.VERSAMMLUNG_VALIDATE_SIGNATURE)
  const sendFeedback = functional.use(Routes.VERSAMMLUNG_SIGNATURE_FEEDBACK)
  const [isValid, setIsValid] = useState<Valid>(Valid.PENDING)
  const t = useSelector((s) => s[props.TRANSLATION])
  const sigCanvas = createRef<ReactSignatureCanvas>()
  const [signatureBase64, setSignatureBase64] = useState<string>('')
  const [feedback, setFeedback] = useState<FreigabeState>(FreigabeState.PENDING)
  const [correction, setCorrection] = useState<string>('')
  const [pageStatus, setPageStatus] = useState<PageStatus>(PageStatus.PENDING)
  const versammlung = useGetVersammlung(versammlungUUID)

  useEffect(() => {
    const runValidation = async () => {
      const isValid = await validateSignature({ versammlungUUID, mail })
      setIsValid(isValid ? Valid.VALID : Valid.INVALID)
    }
    if (isValid === Valid.PENDING) {
      runValidation()
    }
  }, [versammlungUUID, mail, validateSignature, isValid])

  const saveSignatureLocal = () => {
    if (sigCanvas.current) {
      setSignatureBase64(sigCanvas.current.getCanvas().toDataURL('image/png'))
    }
  }

  const clear = () => {
    if (sigCanvas.current) {
      sigCanvas.current.clear()
    }
  }

  const submit = async () => {
    const status = await sendFeedback({ versammlungUUID, mail, feedback, correction, signatureBase64 })
    if (!!status) {
      setPageStatus(PageStatus.SUCCESS)
    } else {
      setPageStatus(PageStatus.FAILED)
    }
  }

  const getTopItems = () => {
    if (!versammlung) return []
    return versammlung.tagesordnungspunkte.map((top, index) => ({
      title: top.beschreibung,
      content: top.beschlussvorlage,
      id: top.key,
    }))
  }

  return (
    <div className="pt-10">
      <Container>
        <div className="text-xl font-bold text-center mb-10">{t.versammlung.signature.protokoll.headline}</div>
        <Loading loading={isValid !== Valid.PENDING} />
        {isValid === Valid.INVALID && <div className="text-center">{t.versammlung.signature.protokoll.invalid}</div>}
        {isValid === Valid.VALID && (
          <>
            {pageStatus === PageStatus.SUCCESS && (
              <div className="text-center w-full md:w-6/12 mx-auto">{t.versammlung.signature.protokoll.submit.success}</div>
            )}
            {pageStatus === PageStatus.FAILED && (
              <div className="text-center w-full md:w-6/12 mx-auto text-red">{t.versammlung.signature.protokoll.submit.error}</div>
            )}
            {pageStatus === PageStatus.PENDING && (
              <div>
                {feedback === FreigabeState.PENDING && (
                  <div>
                    <div className="text-center w-full md:w-6/12 mx-auto">{t.versammlung.signature.protokoll.pending.title}</div>
                    <div className="flex gap-3 md:gap-5 flex-col md:flex-row items-center md:justify-center mt-5">
                      <Button text={t.versammlung.signature.protokoll.pending.acceptCTA} onClick={() => setFeedback(FreigabeState.ACCEPTED)} />
                      <Button
                        danger
                        text={t.versammlung.signature.protokoll.pending.declineCTA}
                        onClick={() => setFeedback(FreigabeState.DECLINED)}
                      />
                    </div>
                  </div>
                )}
                {feedback === FreigabeState.ACCEPTED && (
                  <div>
                    <div className="flex items-center flex-col gap-3">
                      <div className="text-center w-full md:w-6/12 mx-auto">{t.versammlung.signature.protokoll.accept.title}</div>
                      <div className="w-full md:w-6/12">
                        <SignatureCanvas
                          canvasProps={{
                            className: 'border border-turquoise w-full h-[200px]',
                          }}
                          ref={sigCanvas}
                          onEnd={saveSignatureLocal}
                        />
                      </div>
                      <Button danger text={t.versammlung.signature.protokoll.accept.reset} onClick={clear} />
                      <Button text={t.versammlung.signature.protokoll.accept.submit} onClick={submit} />
                    </div>
                  </div>
                )}
                {feedback === FreigabeState.DECLINED && (
                  <div>
                    <div>
                      <Accordion className="w-full" items={getTopItems()} initIndex={0} />
                      <div className="flex items-center flex-col gap-3">
                        <div className="text-center w-full md:w-6/12 mx-auto">{t.versammlung.signature.protokoll.decline.title}</div>
                        {!!versammlung && <div></div>}
                        <div className="w-full md:w-6/12">
                          <Textarea
                            rows={10}
                            placeholder={t.versammlung.signature.protokoll.decline.placeholder}
                            value={correction}
                            onChange={setCorrection}
                          />
                        </div>
                        <Button text={t.versammlung.signature.protokoll.decline.submit} onClick={submit} />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </>
        )}
      </Container>
    </div>
  )
}

export default Signature

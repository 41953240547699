import { functional } from '@think-internet/zeus-frontend-package'
import Routes from '../../../../../../redux/routes'
import useToast from '../../../../../hooks/useToast'
import { useSelector } from 'react-redux'
import props from '../../../../../../redux/props'

type GetProtocol = () => Promise<void>

type Return = GetProtocol

const useGetProtocol = (uuid: string): Return => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const submit = functional.use(Routes.VERSAMMLUNG_GET_PROTOCOL)
  const toast = useToast()

  const getProtocol: GetProtocol = async () => {
    const resultLink = await submit({ uuid })
    if (!!resultLink) {
      if (typeof window !== 'undefined') {
        const link = document.createElement('a')
        link.href = resultLink
        link.target = '_blank'
        link.rel = 'noopener noreferrer'
        link.click()
      }
    } else {
      toast(t.versammlung.list.getProtocolError)
    }
  }

  return getProtocol
}

export default useGetProtocol

import { useEffect, useState } from 'react'
import props from '../../redux/props'
import { crud } from '@think-internet/zeus-frontend-package'
import { Versammlung } from '../../types/Versammlung/Versammlung'

const useGetVersammlung = (versammlungUUID: string): Versammlung | false => {
  const [versammlung, setVersammlung] = useState<Versammlung | false>(null)
  const versammlungFeature = crud.use(props.VERSAMMLUNG)

  useEffect(() => {
    const pull = async () => {
      const versammlung = await versammlungFeature.get({ uuid: versammlungUUID })
      if (versammlung) {
        setVersammlung(versammlung)
      } else {
        setVersammlung(false)
      }
    }
    if (versammlung === null || (!!versammlung && versammlung.uuid !== versammlungUUID)) {
      pull()
    }
  }, [versammlungUUID, versammlung, versammlungFeature])

  return versammlung
}

export default useGetVersammlung

import { Company } from '../types/Company'
import { Person } from '../types/Person'
import { Type } from '../types/Versammlung/Versammlung'

export const translation = {
  projectName: 'Jade-Portal',

  header: {
    navigation: {
      default: [
        {
          name: 'Login',
          link: '/',
        },
      ],
      GOD: [
        {
          name: 'Home',
          link: '/god',
        },
      ],
      ADMIN: [
        {
          name: 'Home',
          link: '/admin',
        },
        {
          name: 'Natürliche Personen',
          link: '/admin/person',
        },
        {
          name: 'Juristische Personen',
          link: '/admin/company',
        },
        {
          name: 'Einstellungen',
          link: '/admin/settings',
        },
      ],
      MEMBER: [
        {
          name: 'Beteiligungen',
          link: '/member/beteiligungen',
        },
        {
          name: 'Meine Informationen',
          link: '/member/informationen',
        },
      ],
      isLoggedIn: {
        name: 'Abmelden',
      },
    },
  },
  todo: {
    title: 'Aufgaben',
    primary: 'Primäre Aufgaben',
    secondary: 'Sekundäre Aufgaben',
    open: 'öffnen',
    empty: 'Keine Aufgaben',
    prio: {
      all: 'Alle Prioritäten',
      LOW: 'Niedrig',
      MEDIUM: 'Mittel',
      HIGH: 'Hoch',
    },
    targetType: {
      all: 'Alle Personen',
      PERSON: 'Natürliche Personen',
      COMPANY: 'Juristische Personen',
    },
    content: {
      title: {
        STAMMDATEN: (name: string) => 'Fehlende Stammdaten für ' + name,
      },
    },
  },

  person: {
    list: {
      title: 'Natürliche Personen',
      noData: 'Keine Daten vorhanden',
      search: 'Suche',
      showInactive: 'Inaktive Personen anzeigen',
      showDeath: 'Verstorbene Personen anzeigen',
    },
    category: {
      uebersicht: 'Übersicht',
      login: 'Login-Informationen',
      kontakt: 'Kontaktinformationen',
      steuer: 'Steuerliche Informationen',
      beteiligungen: 'Beteiligungen',
      pfaendung: 'Pfändung',
      todesfall: 'Todesfall',
      uebertragung: 'Übertragung',
      inaktivitaet: 'Inaktivität',
    },
    upsert: {
      createTitle: 'Natürliche Person hinzufügen',
      updateTitle: (person: Person) =>
        `Natürliche Person - ${`${person.kontakt.vorname} ${person.kontakt.nachname}`}${
          person.login.identifier ? ` - ${person.login.identifier}` : ''
        }`,
      success: 'Natürliche Person wurde erfolgreich gespeichert',
      error: 'Natürliche Person konnte nicht gespeichert werden',
      errorLoading: 'Natürliche Person konnte nicht geladen werden',
      loading: 'Natürliche Person wird angelegt...',
      createCTA: 'Person anlegen',
    },
  },

  company: {
    list: {
      title: 'Juristische Personen',
      noData: 'Keine Daten vorhanden',
      search: 'Suche',
      showInactive: 'Inaktive Personen anzeigen',
    },
    overview: {
      geschaeftsfuehrer: 'Geschäftsführer:',
      beiraete: 'Beiräte:',
    },
    category: {
      overview: 'Übersicht',
      loginInformation: 'Login-Informationen',
      base: 'Stammdaten',
      steuer: 'Steuerliche Informationen',
      kapitalisierung: 'Kapitalisierung',
      bank: 'Bankverbindung',
      kontaktpersonen: 'Kontaktpersonen',
      logo: 'Firmenlogo',
      gesellschafter: 'Gesellschafter',
      beteiligungen: 'Beteiligungen',
      mehrheiten: 'Mehrheiten',
      beschlussfaehigkeit: 'Beschlussfähigkeit',
      kapitalerhoehung: 'Kapitalerhöhung',
      insolvenz: 'Insolvenz',
      administratoren: 'Administratoren',
      uebertragung: 'Übertragung',
      einladungsfrist: 'Einladungsfrist',
      sonderbetriebsEinnahmenAusgaben: 'IMPORT SONDERBETRIEBSEINNAHMEN/ -AUSGABEN',
      inaktivitaet: 'Inaktivität',
      importGUE: 'IMPORT GUE',
    },
    kontaktpersonen: {
      hint: 'Kontaktpersonen sind die Ansprechpartner der jeweiligen Gesellschaft.',
      empty: 'Keine Kontaktpersonen vorhanden',
      attributes: {
        name: 'Name',
        mail: 'E-Mail',
        phone: 'Telefon',
      },
      add: {
        cta: 'bearbeiten',
        title: 'Kontaktperson hinzufügen',
        external: {
          title: 'Externe Kontaktperson hinzufügen',
          confirm: '+ hinzufügen',
        },
      },
    },
    gesellschafter: {
      kapitalPrefix: 'Kapital: ',
      stimmenPrefix: 'Stimmen: ',
      usedOf: ' von insgesamt ',
    },
    logo: {
      hint: 'Das Firmenlogo wird in unterschiedlichen Dokumenten verwendet.',
    },
    mehrheiten: {
      custom: {
        title: 'Individuelle Mehrheiten',
        empty: 'Keine individuellen Mehrheiten vorhanden',
      },
      upsert: {
        titleCreate: 'Mehrheit hinzufügen',
        titleUpdate: 'Mehrheit hinzufügen',
        createCTA: 'Mehrheit hinzufügen',
      },
      help: {
        title: 'Info',
        items: [
          {
            title: 'Stimmenthaltung gelten als nicht abgegebene Stimme',
            text: 'Dieses Feld ist bei Personengesellschaften zu wählen, wenn diese Regelung im Vertrag vorhanden ist. Bei Kapitalgesellschaften ist dieses Feld zu wählen, wenn keine Regelung im Gesellschaftsvertrag vorhanden ist.',
          },
          {
            title: 'Stimmenthaltungen gelten als Gegenstimme',
            text: 'Dieses Feld ist bei Personengesellschaftgen zu wählen, wenn keine Regelung im Vertrag vorhanden ist.',
          },
          {
            title: 'Stimmenthaltungen gelten als Zustimmung',
            text: 'Dieses Feld ist bei Personengesellschaften und Kapitalgesellschaften zu wählen wenn es eine entsprechende Regelung im Gesellschaftsvertrag gibt',
          },
        ],
      },
    },
    beschlussfaehigkeit: {
      sections: {
        minKapital: 'Anwesenheit von Kommanditkapital / Stammkapital - min. ',
        minKommanditisten: 'Anwesenheit der Anzahl von Kommanditisten / Gesellschafter - min. ',
        minKomplementaere: 'Anwesenheit der Anzahl von Komplementären - min. ',
        minStimmen: 'Anwesenheit der Anzahl von Stimmen - min. ',
        bestimmteKomplementaere: 'Anwesenheit von benannten Komplementären - ',
        hinweis: 'Sonderfälle der Beschlussfähigkeit',
      },
      bestimmteKomplementaerePersonList: {
        add: {
          title: 'Komplementäre hinzufügen',
        },
        empty: 'Keine benannten Komplementäre vorhanden',
      },
    },
    kapitalerhoehung: {
      add: {
        aktuelleKapitalisierung: 'Aktuelle Kapitalisierung',
        aktuelleStimmenanzahl: 'Aktuelle Stimmenanzahl',
        cta: 'Erhöhung hinzufügen',
        title: 'Erhöhung hinzufügen',
      },
      list: {
        empty: 'Keine Erhöhungen vorhanden',
      },
    },
    administratoren: {
      hint: 'Administratoren erhalten Benachrichtigungen zur jeweiligen Gesellschaft.',
      empty: 'Keine Administratoren vorhanden',
      attributes: {
        name: 'Name',
        mail: 'E-Mail',
        phone: 'Telefon',
      },
      list: {
        primaer: 'Primär-Administrator',
        sekundaer: 'Sekundär Administratoren',
      },
      add: {
        cta: 'bearbeiten',
        empty: 'Keine Administratoren vorhanden',
        primaer: {
          title: 'Primär-Administrator bearbeiten',
        },
        sekundaer: {
          title: 'Secondäre Administratoren bearbeiten',
        },
      },
    },
    einladungsfrist: {
      hint: 'Die vertragliche Frist für die Einladungen einer Versammlungen jeglicher Art.',
    },
    upsert: {
      createTitle: 'Juristische Person hinzufügen',
      updateTitle: (company: Company) => `Juristische Person - ${company.base.name}`,
      success: 'Juristische Person wurde erfolgreich gespeichert',
      error: 'Juristische Person konnte nicht gespeichert werden',
      errorLoading: 'Juristische Person konnte nicht geladen werden',
      loading: 'Juristische Person wird angelegt...',
      createCTA: 'Person anlegen',
    },
    navigation: [
      {
        name: 'Übersicht',
        link: (uuid: string) => `/admin/company/${uuid}`,
      },
      {
        name: 'Gesellschafterversammlung',
        link: (uuid: string) => `/admin/company/versammlung/${Type.gesellschafterversammlung}/${uuid}`,
      },
      {
        name: 'Datenspeicher',
        link: (uuid: string) => `/admin/company/storage/${uuid}`,
      },
      // {
      //   name: 'Informationsveranstaltung',
      //   link: (uuid: string) => `/admin/company/versammlung/${Type.informationsveranstaltung}/${uuid}`,
      // },
      // {
      //   name: 'Beiratsversammlung',
      //   link: (uuid: string) => `/admin/company/versammlung/${Type.beiratsversammlung}/${uuid}`,
      // },
      // {
      //   name: 'Treugeberversammlung',
      //   link: (uuid: string) => `/admin/company/versammlung/${Type.treugeberversammlung}/${uuid}`,
      // },
      // {
      //   name: 'Schriftliche Abstimmungsverfahren',
      //   link: (uuid: string) => `/admin/company/versammlung/${Type.schriftliches_abstimmungsverfahren}/${uuid}`,
      // },
    ],
  },

  versammlung: {
    [Type.gesellschafterversammlung]: 'Gesellschafterversammlung',
    [Type.informationsveranstaltung]: 'Informationsveranstaltung',
    [Type.beiratsversammlung]: 'Beiratsversammlung',
    [Type.treugeberversammlung]: 'Treugeberversammlung',
    [Type.schriftliches_abstimmungsverfahren]: 'Schriftliche Abstimmungsverfahren',
    upsert: {
      cta: '+ anlegen',
      titleSuffix: ' bearbeiten',
      addTOPCTA: '+ Tagesordnungspunkt hinzufügen',
      removeTOPCTA: 'Tagesordnungspunkt löschen',
      removeTOPCTAConfirmationText: (name?: string) =>
        !!name ? `Möchten Sie den Tagesordnungspunkt "${name}" wirklich löschen?` : 'Möchten Sie den Tagesordnungspunkt wirklich löschen?',
      votingOptions: {
        addCTA: '+ Abstimmungsoption hinzufügen',
      },
      sendTestMail: {
        hint: 'Um eine Freigabe einholen zu können, muss mindestens eine Test-E-Mail verschickt werden.',
        cta: 'Test-E-Mail senden',
        success: 'Test-E-Mail wurde erfolgreich versendet',
        error: 'Test-E-Mail konnte nicht versendet werden',
      },
      freigabe: {
        empfaenger: {
          title: 'Freigabe-Empfänger',
          addCTA: '+ Empfänger hinzufügen',
          confirmCTA: 'Empfänger hinzufügen',
        },
        send: {
          cta: 'Freigabe senden',
          title: 'Freigabe senden',
          hint: 'Sind Sie sich sicher, dass Sie die Freigabe an alle angegebenen Empfängern senden möchten?',
          success: "Freigabe E-Mail's wurden erfolgreich versendet",
          error: "Freigabe E-Mail's konnten nicht versendet werden",
          alreadyHint: "Freigabe E-Mail's wurden bereits versendet",
        },
        manual: {
          cta: 'Manuelle Freigabe',
          title: 'Manuelle Freigabe',
          hint: 'Sind Sie sich sicher, dass Sie die Freigabe manuell durchführen möchten?',
        },
      },
      sections: {
        dateTimeLocation: 'Datum, Uhrzeit & Standort',
        tagesordnungspunkte: 'Tagesordnungspunkte',
        einladung: 'Einladung',
        freigabe: 'Freigabe',
      },
      success: 'Versammlung wurde erfolgreich gespeichert',
    },
    list: {
      upcoming: 'Zukünftige Versammlungen:',
      past: 'Vergangene Versammlungen:',
      loading: 'Versammlungen werden geladen...',
      editCTA: 'Bearbeiten',
      getProtocolCTA: 'Protokoll herunterladen',
      getProtocolError: 'Protokoll konnte nicht heruntergeladen werden',
      moderateCTA: 'Moderation',
      demoCTA: 'Vorschau',
      broadcast: {
        title: 'Protokoll versenden',
        open: 'Protokoll versenden',
        text: 'Möchten Sie das Protokoll an alle Beteiligten versenden?',
        success: 'Das Protokoll wurde erfolgreich versandt.',
        error: 'Das Protokoll konnte nicht versandt werden.',
      },
    },
    attributes: {
      datumUhrzeit: 'Datum & Uhrzeit',
      abgabefrist: 'Abgabefrist',
      einladungsfristIgnorieren: 'Einladungsfrist ignorieren',
      standort: 'Standort',
      meetingLink: 'Meeting-Link',
      meetingID: 'Meeting-ID',
      meetingPassword: 'Meeting-Passwort',
      ONLINE: 'Online',
      OFFLINE: 'Offline',
      beschreibung: 'Beschreibung',
      type: 'Art des Tagesordnungspunkts',
      beschlussvorlage: 'Beschlussvorlage (falls vorhanden)',
      protokoll: 'Protokoll des Tagesordnungspunkts',
      notiz: 'Interne Notiz',
      mehrheit: 'Mehrheit (falls nötig)',
      emptySelectionMehrheit: 'Keine Mehrheit ausgewählt',
      betreff: 'Betreff',
      anschreiben: 'Anschreiben',
      testMail: 'Test-E-Mail Empfänger',
      INFO: 'Information',
      VOTING_DEFAULT: 'Abstimmung',
      VOTING_OPTIONS: 'Abstimmung mit Optionen',
      votingOptions: 'Abstimmungsoptionen',
      votingName: 'Name der Abstimmungsoption',
    },
    moderate: {
      loading: 'Versammlung wird geladen...',
      search: 'Suche',
      votingList: {
        title: 'Anwesenheiten & Abstimmungen',
        anwesend: {
          toggle: 'anwesend',
        },
        globalVoting: {
          title: 'Für alle Beteiligungen',
        },
      },
      beschlussfaehigkeit: {
        title: 'Beschlussfähigkeit',
        prefix: (type) => `Die ${type} ist `,
        state: {
          yes: 'beschlussfähig',
          no: 'nicht beschlussfähig',
        },
        suffix: '.',
      },
      next: {
        title: (type) => `${type} beginnen`,
        start: (type) => `${type} starten`,
        demoStart: 'Vorschau der TOPs',
        demoStartHint: (type) => `(${type} wird nicht gestartet)`,
      },
      detail: {
        title: 'Tagesordnungspunkt',
        files: 'Anhänge',
        mehrheit: {
          required: 'Notwendige Mehrheit',
          current: 'Aktuelle Mehrheit',
          votingAmount: 'Abgestimmt',
          stimmen: {
            minPrefix: 'mindestens ',
            mid: ' von ',
            suffix: ' Stimmen',
          },
          beteiligungAmount: {
            mid: ' von ',
            suffix: ' Beteiligungen',
          },
        },
      },
      end: {
        saveDate: {
          cta: 'Datum & Uhrzeit speichern',
          hint: 'Die Veranstaltung ist Beendet, speichern Sie Datum und Uhrzeit. Die Protokolle der Tageordnungspunkte können weiterhin bearbeitet werden.',
          savedPrefix: 'Datum & Uhrzeit gespeichert: ',
          demoMode: 'Datum & Uhrzeit kann nicht gespeichert werden, da Sie sich im Demo-Modus befinden.',
        },
        finalize: {
          cta: 'Versammlung abschließen',
          hint: 'Das Protokoll wird an ausgewählte Personen zur Unterschrift gesendet. Nachdem alle Unterschriften eingegangen sind, kann die Versammlung abgeschlossen werden.',
          hintAllSignaturesReceived: 'Das Protokoll wurde von allen Personen unterschrieben. Die Versammlung kann jetzt abgeschlossen werden.',
          demoMode: 'Die Versammlung kann nicht abgeschlossen werden, da Sie sich im Demo-Modus befinden.',
          demoModePersonSelect: 'Personen zur Unterschrift können nicht ausgewählt werden, da Sie sich im Demo-Modus befinden.',
          success: 'Die Versammlung wurde erfolgreich abgeschlossen. Sie werden jetzt auf die Übersichtsseite weitergeleitet.',
        },
        triggerSignatures: {
          success: 'Unterschriftenprozess wurde erfolgreich gestartet',
          error: 'Unterschriftenprozess konnte nicht gestartet werden',
          missingDate: 'Bitte speichern Sie das Datum und die Uhrzeit der Versammlung, bevor Sie den Unterschriftenprozess starten.',
        },
      },
      navigate: {
        back: 'Zurück',
        next: 'Weiter',
        error: {
          save: 'Versammlung konnte nicht zwischengespeichert werden. Bitte überprüfen Sie Ihre Internetverbindung.',
        },
      },
      error: {
        loading: 'Versammlung konnte nicht geladen werden',
      },
    },
    signature: {
      protokoll: {
        headline: 'Bestätigung des Protokolls',
        invalid: 'Sie haben das Protokoll bereits unterschrieben.',
        pending: {
          title: 'Nachdem Sie das Protokoll gesichtet haben, können Sie es hier bestätigen oder eine Korrektur anfordern.',
          acceptCTA: 'Protokoll bestätigen',
          declineCTA: 'Korrektur anfordern',
        },
        accept: {
          title: 'Unterschreiben Sie das Protokoll im folgenden Feld.',
          reset: 'Unterschrift zurücksetzen',
          submit: 'Unterschrift speichern',
        },
        decline: {
          title: 'Dokumentieren Sie Ihre Korrektur im folgenden Feld.',
          placeholder: 'Ihre Korrektur...',
          submit: 'Korrektur speichern',
        },
        submit: {
          success: 'Vielen Dank.',
          error: 'Ihre Eingaben konnten nicht gespeichert werden. Bitte versuchen Sie es erneut.',
        },
      },
    },
  },

  attributes: {
    username: 'Benutzername',
    password: 'Passwort',
    role: 'Rolle',
    titel: 'Titel',
    titelItems: {
      DR: 'Dr.',
      DRDR: 'Dr. Dr.',
      PROFDR: 'Prof. Dr.',
      PROFDRDR: 'Prof. Dr. Dr.',
    },
    ansprache: 'Anrede',
    anspracheItems: {
      HERR: 'Herr',
      FRAU: 'Frau',
      DIVERS: 'Divers',
    },
    vorname: 'Vorname',
    nachname: 'Nachname',
    geburtsname: 'Geburtsname',
    geburtsdatum: 'Geburtsdatum',
    strasseHausnummer: 'Straße / Hausnummer',
    postleitzahl: 'Postleitzahl',
    stadt: 'Stadt',
    land: 'Land',
    telefon: 'Telefon',
    mobilTelefon: 'Mobiltelefon',
    mail: 'E-Mail',
    onliner: 'Onliner/Offliner',
    steuernummer: 'Steuernummer',
    steuerID: 'Steuer-ID',
    mandantennummer: 'Mandantennummer',
    inaktivitaet: 'Auf Inaktiv setzen',
    name: 'Name',
    rechtsform: 'Rechtsform',
    externallyManaged: 'extern betreute Gesellschaft (nicht in eigener Verwaltung)',
    umsatzsteuerID: 'Umsatzsteuer-ID',
    initialKapital: 'Startkapital',
    stimmenGesamt: 'Stimmen gesamt',
    iban: 'IBAN',
    bic: 'BIC',
    beteiligungsHoehe: 'Beteiligungshöhe',
    gesamtKapital: 'Gesamtkapital',
    kapital: 'Kapital',
    stimmen: 'Stimmen',
    beteiligungsnummer: 'Beteiligungsnummer',
    logoKey: 'Logo',
    enthaltungenImSchriftlichenAbstimmungsverfahren: 'Enthaltungen im schriftlichen Abstimmungsverfahren',
    enthaltungenBeiGesellschafterversammlungen: 'Enthaltungen bei Gesellschafterversammlungen',
    minPercent: 'Mindestprozent',
    ABSOLUTE: 'Anzahl der vorhandenen Stimmen',
    RELATIVE: 'Anzahl der abgegebenen Stimmen',
    text: 'Referenzfeld für den Paragraphen im Gesellschaftsvertrag',
    alsStandartVerwenden: 'Als grundsätzliche Mehrheit für diese Gesellschaft verwenden',
    standart: 'Standard',
    custom: 'Individuelle Mehrheiten',
    keineVoraussetzungen: 'keine Voraussetzung zur Beschlussfähigkeit',
    minKapital: 'Mindestanzahl an Kapital',
    minKommanditisten: 'Mindestanzahl an Anwesenden',
    minKomplementaere: 'Mindestanzahl an Komplementären',
    minStimmen: 'Mindestanzahl an Stimmen',
    hinweis: 'Hinweis für die Gesellschafterversammlung',
    erhoehungsbetrag: 'Erhöhungsbetrag',
    erhoehungDerStimmen: 'Erhöhung der Stimmen',
    anfangszeitpunkt: 'Anfangszeitpunkt',
    insolvenzAktiv: 'Es liegt eine aktive Insolvenz vor',
    primaer: 'Primär-Administrator',
    sekundaer: 'Sekundäre Administratoren',
    inTagen: 'Einladungsfrist in Tagen',
    praendungAktiv: 'Es liegt eine aktive Pfändung vor',
    todesfallAktiv: 'Nutzer ist verstorben',
    todeszeitpunkt: 'Todeszeitpunkt',
    artDerBeteiligung: {
      KOMPLEMENTAER: 'Komplementär',
      KOMMANDITIST: 'Kommanditist',
      GESELLSCHAFTER: 'Gesellschafter',
      MITTELBAR_BETEILIGTER: 'mittelbar Beteiligter',
    },
    position: {
      GESCHAEFTSFUEHRER: 'Geschäftsführer',
      BEIRAT: 'Beirat',
      UNDEFINED: 'Nicht zutreffend',
    },
    entnahmeBerechtigt: 'Entnahme berechtigt',
    entnahmeBerechtigtHint: 'Der Kommanditist erhält Auszahlungen bei Entnahmen.',
    entnahmeGesperrt: 'Entnahme gesperrt',
    entnahmeGesperrtHint: 'Der der Kommanditist ist entnahmeberechtigt, aber temporär gesperrt (bspw. bei laufendem Insolvenzantrag)',
    istBeteiligung: 'Beteiligung',
    istBeteiligungHint: '',
    gesellschaft: 'Gesellschaft',
    freigabeStatus: {
      PENDING: 'Ausstehend',
      ACCEPTED: 'Freigegeben',
      DECLINED: 'Abgelehnt',
    },
  },

  storage: {
    title: 'Datenspeicher',
    itemList: {
      empty: 'Keine Inhalte vorhanden',
    },
    createFolder: {
      cta: 'Ordner erstellen',
      title: 'Ordner erstellen',
      placeholder: 'Ordnername',
    },
    uploadFile: {
      cta: 'Datei hochladen',
      title: 'Datei hochladen',
    },
    error: {
      createFolder: 'Ordner konnte nicht erstellt werden',
      emptyFolderName: 'Ordnername darf nicht leer sein',
      removeItem: 'Element konnte nicht gelöscht werden',
    },
  },

  member: {
    beteiligungen: {
      title: 'Beteiligungen',
      view: {
        title: (company: Company) => `Beteiligung - ${company.base.name}`,
      },
    },
    informationen: {
      title: (person: Person) =>
        `${`${person.kontakt.vorname} ${person.kontakt.nachname}`}${person.login.identifier ? ` - ${person.login.identifier}` : ''}`,
    },
  },

  login: {
    cta: 'Anmelden',
    stayLoggedIn: 'Angemeldet bleiben',
    forgotPassword: 'Passwort vergessen?',
    error: 'Anmeldung fehlgeschlagen. Falsche E-Mail oder Passwort.',
  },

  settings: {
    title: 'Einstellungen',
    storageTemplate: {
      title: 'Datenspeicher',
      hint: 'Hier kann die allgemeine Datenstrukur aller Gesellschaften eingestellt werden. Funktionen wie die Versammlungsarten erstellen automatisch die entsprechenden Strukturen.',
    },
  },

  footer: {
    links: [
      { name: 'Impressum', link: 'https://www.jade-concept.de/imprint' },
      { name: 'Datenschutz', link: 'https://www.jade-concept.de/data-protection' },
    ],
  },

  generic: {
    company: {
      name: 'Jade Concept Kapitalanlageberatungsgesellschaft mbH',
      street: 'Friedrich-Ebert-Straße 28',
      city: '26316 Varel',
      phone: '+49 (0) 44 51 80 96 55',
      mail: 'energie@jade-concept.de',
    },
    personen: 'Personen',
    person: 'Person',
    phonePrefix: 'Telefon: ',
    mailPrefix: 'E-Mail: ',
    websitePrefix: 'Webseite: ',
    salutation: 'Anrede',
    name: 'Name',
    username: 'Benutzername',
    firstname: 'Vorname',
    lastname: 'Nachname',
    birthday: 'Geburtsdatum',
    street: 'Straße, Hausnummer',
    companyName: 'Firmenname',
    club: 'Verein (optional)',
    city: 'PLZ, Ort',
    mail: 'E-Mail-Adresse',
    message: 'Ihre Anfrage...',
    phone: 'Telefonnummer',
    password: 'Passwort',
    passwordRepeat: 'Passwort wiederholen',
    createdOn: 'Erstellt am',
    confirm: 'Bestätigen',
    cancel: 'Abbrechen',
    close: 'Schließen',
    save: 'Speichern',
    delete: 'Löschen',
    send: 'Senden',
    error: 'Ein unerwarteter Fehler ist aufgetreten. Bitte versuche es später erneut.',
    acceptAGB: 'Ich akzeptiere die AGB',
    percent: ' %',
    kilometerSuffix: ' km',
    upload: 'Hochladen',
    login: 'Anmelden',
    logout: 'Abmelden',
    export: 'Export',
    import: 'Import',
    unsavedChanges: 'Es gibt ungespeicherte Änderungen',
  },

  UI: {
    removeConfirmation: {
      title: 'Bestätigung',
      text: (itemName: string) => `Möchten Sie "${itemName}" wirklich löschen?`,
    },
    save: {
      error: 'Speichern fehlgeschlagen',
    },
    artDerBeteiligung: {
      title: 'Art der Beteiligung',
    },
    position: {
      title: 'Position',
    },
    beteiligungManager: {
      upsert: {
        cta: 'Beteiligung hinzufügen',
        createTitle: 'Beteiligung hinzufügen',
        updateTitle: 'Beteiligung bearbeiten',
        repeatCompanyName: 'Gesellschaftsname wiederholen',
        checkHint: 'Ich erkläre hiermit, dass alle Angaben auf Richtigkeit geprüft wurden und korrekt sind.',
        success: 'Beteiligung wurde erfolgreich hinzugefügt',
        error: {
          missingCompany: 'Bitte wählen Sie eine Gesellschaft aus.',
          companyNameNoMatch: 'Der Gesellschaftsname stimmt nicht überein.',
          notEnoughKapital: 'Die Beteiligungshöhe übersteigt das verfügbare Kapital.',
          notEnoughStimmen: 'Die Stimmenanzahl übersteigt die verfügbaren Stimmen.',
          generic: 'Beteiligung konnte nicht hinzugefügt werden',
        },
        remove: {
          text: (companyName: string) => `Möchten Sie diese Beteiligung an "${companyName}" sicher löschen?`,
          success: 'Beteiligung wurde erfolgreich gelöscht',
          error: 'Beteiligung konnte nicht gelöscht werden',
        },
      },
      list: {
        empty: 'Keine Beteiligungen vorhanden',
      },
      error: {
        list: 'Beteiligungen konnten nicht geladen werden',
      },
    },
    uebertragungManager: {
      add: {
        cta: 'Übertragung hinzufügen',
        createTitle: 'Übertragung hinzufügen',
        repeatCompanyName: 'Gesellschaftsname wiederholen',
        checkHint: 'Ich erkläre hiermit, dass alle Angaben auf Richtigkeit geprüft wurden und korrekt sind.',
        success: 'Übertragung wurde erfolgreich hinzugefügt',
        targetTitle: 'Übertragen an:',
        targetCompany: 'Juristische Person',
        targetPerson: 'Natürliche Person',
        error: {
          generic: 'Übertragung konnte nicht hinzugefügt werden',
          originBeteiligungMissing: 'Bitte wählen Sie eine Beteiligung aus.',
          targetMissing: 'Bitte wählen Sie eine Gesellschaft oder eine Person aus.',
        },
      },
      list: {
        from: 'Beteiligung an',
        to: 'Übertragen an',
        empty: 'Keine Übertragungen vorhanden',
      },
      error: {
        list: 'Übertragungen konnten nicht geladen werden',
      },
    },
    personList: {
      empty: 'Keine Personen vorhanden',
    },
    addFiles: {
      title: 'Datei hinzufügen',
      cta: 'Datei wählen',
      empty: 'Keine Dateien vorhanden',
    },
    personSelect: {
      search: 'Suche nach natürlichen Personen',
      noResults: 'Keine Ergebnisse',
    },
    beteiligungSelect: {
      search: 'Suche nach Beteiligungen',
      noResults: 'Keine Ergebnisse',
    },
    companySelect: {
      search: 'Suche nach juristischen Personen',
      noResults: 'Keine Ergebnisse',
    },
    mehrheit: {
      title: 'Mehrheit',
    },
    enthaltung: {
      title: 'Enthaltung',
      NICHT_ABGEGEBEN: 'Stimmenthaltung gelten als nicht abgegebene Stimme',
      GEGENSTIMME: 'Stimmenthaltungen gelten als Gegenstimme',
      ZUSTIMMUNG: 'Stimmenthaltungen gelten als Zustimmung',
    },
    contactPersonSelect: {
      toggleLabel: 'Plattformweite Suche',
      search: 'Suche nach Personen',
      noResults: 'Keine Ergebnisse',
    },
    accordion: {
      missingProps: {
        neededPrefix: 'Fehlende Eingaben: ',
        optionalPrefix: 'Optional fehlende Eingaben: ',
      },
    },
    onliner: {
      title: 'Onliner/Offliner',
      items: [
        { label: 'Onliner', value: true },
        { label: 'Offliner', value: false },
      ],
    },
    country: {
      title: 'Land',
    },
    companyLegalTypes: {
      title: 'Rechtsform',
      types: {
        KG: 'KG',
        GMBH: 'GmbH',
        GMBH_KG: 'GmbH & Co. KG',
        AG: 'AG',
        UG: 'UG',
        OHG: 'OHG',
        GBR: 'Gbr',
        JPDOER: 'juristische Personen des öffentlichen Rechts',
      },
    },
    level: {
      title: 'Sicherheitsstufe',
      select: {
        ADMIN: 'Administrator',
        MEMBER: 'Benutzer',
      },
    },
    autocomplete: {
      searchError: 'Suche konnte nicht ausgeführt werden.',
      searchEmpty: 'Keine Ergebnisse',
    },
    dropdown: {
      emptySelection: 'Bitte wählen',
    },
    selectableTagList: {
      showMore: 'Mehr anzeigen',
      showLess: 'Weniger',
    },
    textarea: {
      max: {
        prefix: 'maximal ',
        suffix: ' Zeichen',
      },
    },
    fileSelect: {
      fileToLargePrefix: 'Die ausgewählte Datei ist zu groß: ',
      allowedFileTypes: 'Erlaubte Dateitypen: ',
      allowedFileSize: 'Max. erlaubte Dateigröße: ',
    },
    dnd: {
      text: 'Bewerbung anhängen',
      hint: '(Erlaubt ist eine PDF-Datei bis zu 25 MB)',
      selectedPrefix: 'Gewählte PDF: ',
      error: {
        size: 'Deine Ausgewählte PDF ist zu groß. Maximal erlaubte Dateigröße: 25 MB',
        type: 'Bitte lade nur PDF-Dateien hoch.',
      },
    },
  },

  resetPassword: {
    passwordsDontMatch: 'Passwörter stimmen nicht überein',
    passwordReset: 'Passwort wurde erfolgreich geändert',
    save: 'Ändern',
    password: 'Passwort',
    passwordRepeat: 'Passwort wiederholen',
    title: 'Passwort ändern',
  },
}

export const langKey = 'DE'
